import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Planning`}</h1>
    <ol>
      <li parentName="ol">{`Open Google Chrome and then navigate to provision-portal.bddevops.com.`}</li>
      <li parentName="ol">{`Log in using your BD credentials.`}</li>
      <li parentName="ol">{`Click the Facilities tab on the left hand side.`}</li>
      <li parentName="ol">{`Click the customer name and select Facility Name.`}</li>
      <li parentName="ol">{`Under Station Deployments, click CREATE NEW UPDATE.`}</li>
      <li parentName="ol">{`In Release drop down menu select appropriate release and click SAVE.`}</li>
      <li parentName="ol">{`Under the update, click Plan Waves icon.
The PLANNING page opens. All available stations to be updated are displayed under Unassigned Stations in the Wave column.`}</li>
      <li parentName="ol">{`For the first unassigned station, under the Wave column, select the Unassigned drop down and then click Create New Wave…
The Create New Wave dialog appears. A wave is a set of stations that are allowed to go offline during the same time. The point of waves is to allow for the appropriate devices to go offline at the appropriate time.`}</li>
      <li parentName="ol">{`Under Name ,enter the name of the wave.`}</li>
      <li parentName="ol">{`Under Starts ,click the calender icon and specify the start date and time for the wave.`}</li>
      <li parentName="ol">{`Enter any additional notes specific to the wave and then click SAVE.
A new wave is created which includes the selected station.`}</li>
      <li parentName="ol">{`If there are remaining stations under Unassigned Stations, under the Wave column, click the
Unassigned list and then select the name of the newly created wave.
Doing so schedules that device to be updated at the same time as the other devices included in that
wave. To schedule this station update for its own wave, follow steps 8-11.`}</li>
    </ol>
    <h1>{`Executing`}</h1>
    <ol>
      <li parentName="ol">{`Open Google Chrome and then navigate to provision-portal.bddevops.com.`}</li>
      <li parentName="ol">{`Log in using your BD credentials.`}</li>
      <li parentName="ol">{`Click the Facilities tab on the left hand side.`}</li>
      <li parentName="ol">{`Click the customer name and select Facility Name.`}</li>
      <li parentName="ol">{`Under Stations Deployments, select the upgrade deployment name.`}</li>
      <li parentName="ol">{`Under the Actions culumn click EXECUTE WAVES.
The execute page displays the status of the waves.`}</li>
      <li parentName="ol">{`Click on the wave.`}</li>
      <li parentName="ol">{`Do one of the following:`}
        <ul parentName="li">
          <li parentName="ul">{`To update all devices in the wave automatically, next to the wave name, click START ALL. To update each device step by step, under the Actions column, click the play icon.`}</li>
          <li parentName="ul">{`The name of the Step as well as the Status is displayed in their own columns. After steps status is completed, click the play icon to execute the next step.`}</li>
        </ul>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      